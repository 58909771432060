// Allianz Styles Import
@import "@angular/cdk/overlay-prebuilt.css";
@import "@angular/cdk/a11y-prebuilt.css";
// @import "./ndbx-custom-base"; // TODO: Switch back to NDBX-BASE and remove fonts from this repo, once we can upload woff files to the DAM
@import "@allianz/ngx-ndbx/scss/ndbx-base";
@import "@aposin/ng-aquila/styles/theming";
@import "@aposin/ng-aquila/css/utilities.css";

// AzCH Styles Import
@import "assets/css/azch_theme";
@import "assets/css/form";
@import "@allianzch/ngx-core-ch/src/lib/assets/shared-styles/index";
@import "@allianzch/ngx-core-ch/src/lib/assets/css/all-in";
@import "./theme";

// @include nx-build-theme(azch_theme);
@include nx-build-theme(needform);

.paddingcleaner {
  padding: 0 !important;
}

.margincleaner {
  margin: 0 !important;
}

html,
body {
  height: 100%;
}

h1 {
  font-size: nx-theme(heading-01-font-size);
  line-height: nx-theme(heading-01-line-height);
  font-weight: nx-theme(heading-01-font-weight);
  letter-spacing: nx-theme(heading-01-letter-spacing);

  @media (max-width: 703px) {
    font-size: nx-theme(heading-01-mobile-font-size);
    line-height: nx-theme(heading-01-mobile-line-height);
    font-weight: nx-theme(heading-01-mobile-font-weight);
    letter-spacing: nx-theme(heading-01-mobile-letter-spacing);
  }
}

h2 {
  font-size: nx-theme(heading-02-font-size);
  line-height: nx-theme(heading-02-line-height);
  font-weight: nx-theme(heading-02-font-weight);
  letter-spacing: nx-theme(heading-02-letter-spacing);

  @media (max-width: 703px) {
    font-size: nx-theme(heading-02-mobile-font-size);
    line-height: nx-theme(heading-02-mobile-line-height);
    font-weight: nx-theme(heading-02-mobile-font-weight);
    letter-spacing: nx-theme(heading-02-mobile-letter-spacing);
  }
}

h3 {
  font-size: nx-theme(heading-03-font-size);
  line-height: nx-theme(heading-03-line-height);
  font-weight: nx-theme(heading-03-font-weight);
  letter-spacing: nx-theme(heading-03-letter-spacing);

  @media (max-width: 703px) {
    font-size: nx-theme(heading-03-mobile-font-size);
    line-height: nx-theme(heading-03-mobile-line-height);
    font-weight: nx-theme(heading-03-mobile-font-weight);
    letter-spacing: nx-theme(heading-03-mobile-letter-spacing);
  }
}
// For IE
// sass-lint:disable no-vendor-prefixes
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  p {
    margin: 0 !important;
  }
}

// For IE Edge
// sass-lint:disable no-vendor-prefixes
@supports (-ms-accelerator: true) {
  p {
    margin: 0 !important;
  }
}

// Kill Margins and Paddings
// <h1>
.nx-heading--subsection-large {
  margin-bottom: 0 !important;
}

// h3
.nx-heading--subsection-small {
  margin-bottom: 0 !important;
}

.nx-heading--subsection-xsmall {
  margin-bottom: 0 !important;
}

p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.as-content-min-height {
  min-height: calc(100vh - 200px);
}

.c-header__wrapper {
  max-width: 1170px;
  padding: 0 30px;
  margin: auto;
}
.c-header-metabar__content {
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.c-header__logo img {
  vertical-align: middle;
  height: 30px;
}

.c-header__logo {
  display: block;
  margin-right: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.c-header-metabar {
  border-bottom: 1px solid #d9d9d9;
  display: none;
}

.c-heading--page {
  margin-top: 0;
}

.c-icon {
  font-family: "Allianz-Icons";
}
.c-icon--arrow-right:before {
  content: "\e903";
}
.c-icon--check:before {
  content: "\e911";
}
.c-icon--phone-o:before {
  content: "\e910";
}

.c-footer__phone .c-phone-link__title {
  line-height: 29px;
}

.c-phone-link__title {
  display: inline-block;
  margin-right: 11px;
  line-height: 2;
}

.c-phone-link__title--tablet {
  display: none;
}
@media (max-width: 991px) {
  .c-phone-link__title--desktop {
    display: none;
  }
  .c-phone-link__title--tablet {
    display: inline-block;
  }
}

.c-footer__phone .c-phone-link__number {
  line-height: 29px;
  font-weight: 600;
}

.c-phone-link__icon {
  margin-right: 10px;
  font-size: 24px;
  vertical-align: middle;
}
.c-footer__service {
  font-size: 16px;
}

.c-list {
  font-size: 18px;
}

.c-list__item {
  padding-left: 35px;
  position: relative;
}
.c-list__icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
}

@media (min-width: 704px) {
  .c-list__item {
    font-weight: bold;
  }
}

.c-copy {
  margin-bottom: 30px;
}

.c-copy--large {
  font-size: 18px;
  line-height: 24px;
}

.u-text-center,
.u-text-align-center {
  text-align: center;
}

@media (max-width: 703px) {
  .nx-formfield__wrapper {
    padding-bottom: 0 !important;
  }
}

.c-header__wrapper {
  max-width: 1170px;
  padding: 0 30px;
  margin: auto;
}
.c-header-metabar__content {
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.c-header__logo img {
  vertical-align: middle;
  height: 30px;
}

.c-header__logo {
  display: block;
  margin-right: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.c-header-metabar {
  border-bottom: 1px solid #d9d9d9;
  display: none;
}

.c-heading--page {
  margin-top: 0;
}

.c-icon {
  font-family: "Allianz-Icons";
}
.c-icon--arrow-right:before {
  content: "\e903";
}
.c-icon--check:before {
  content: "\e911";
}
.c-icon--phone-o:before {
  content: "\e910";
}

.c-footer__phone .c-phone-link__title {
  line-height: 29px;
}

.c-phone-link__title {
  display: inline-block;
  margin-right: 11px;
  line-height: 2;
}

.c-phone-link__title--tablet {
  display: none;
}
@media (max-width: 991px) {
  .c-phone-link__title--desktop {
    display: none;
  }
  .c-phone-link__title--tablet {
    display: inline-block;
  }
}

.c-footer__phone .c-phone-link__number {
  line-height: 29px;
  font-weight: 600;
}

.c-phone-link__icon {
  margin-right: 10px;
  font-size: 24px;
  vertical-align: middle;
}
.c-footer__service {
  font-size: 16px;
}

.c-list {
  font-size: 18px;
}

.c-list__item {
  padding-left: 35px;
  position: relative;
}
.c-list__icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
}

@media (min-width: 704px) {
  .c-list__item {
    font-weight: bold;
  }
}

.c-copy {
  margin-bottom: 30px;
}

.c-copy--large {
  font-size: 18px;
  line-height: 24px;
}

.u-text-center,
.u-text-align-center {
  text-align: center;
}

@media (max-width: 703px) {
  .nx-formfield__wrapper {
    padding-bottom: 0 !important;
  }
}
